.leaflet-tooltip {
  position: absolute !important;
  padding: 6px !important;
  background-color: transparent !important;
  border: transparent !important; /*  1px solid #fff !important; */
  border-radius: 3px !important;
  color: #222 !important;
  white-space: nowrap !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  pointer-events: none !important;
  box-shadow: '' !important; /* 0 1px 3px rgba(0,0,0,0.4) !important; */
}

.custom-tooltip {
  background-color: #fff !important;
}

.leaflet-control-attribution.leaflet-control {
  display: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.errorFields {
  color: red !important;
}

.upperCase {
  text-transform: uppercase !important;
}

.reference-div {
  margin-top: 120px;
}

.fixed-input {
  width: 100% !important;
}

.modal-alert {
  z-index: 300000 !important;
}

.swal2-input {
  background-color: transparent !important;
  border-color: #4ebbe3 !important;
  width: auto !important;
}

.css-hhvo2v-MuiListItem-root.Mui-selected {
  border: 1px solid #0041a0 !important;
  border-radius: 8px !important;
  background-color: inherit !important;
  box-shadow: none !important;
}
.css-17m19jt-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  height: 4ch !important;
}

.css-12rl710-MuiPaper-root-MuiDialog-paper {
  border-radius: 12px !important;
}
/* .css-zylse7-MuiButtonBase-root-MuiIconButton-root{
  background-color: #0041a0 !important;
  color: #fff !important;
}

.css-zylse7-MuiButtonBase-root-MuiIconButton-root.Mui-disabled{
  background-color: inherit !important;
  color: rgba(0, 0, 0, 0.26);
} */

.card-primary {
  border-radius: 12px !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
  margin-bottom: 20px !important;
}

.card-secondary {
  border-radius: 14px !important;
  box-shadow: rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px !important;
}

.card-item {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
  margin-bottom: 10px;
  border-radius: 10px !important;
}

.main-welcome {
  display: flex;
  flex-direction: column;
  padding-top: 96px;
  padding-bottom: 96px;
  margin: auto;
  max-width: 400px;
  min-height: 90vh;
  text-align: center;
  justify-content: center;
}

.submain-welcome {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
